import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import StatrysLoader from '../../ui/loaders/StatrysLoader';
import MSText from '../../ui/typography/MSText';
import BulbIcon from '../../../images/icons/bulb.svg';
import FileUpload from '../../../images/graphics/upload-doc.png';
import XSText from '../../ui/typography/XSText';
import PropTypes from 'prop-types';
import * as commonReduxActions from '../../commonReduxStore/reduxStore/action';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import BackButton from '../../ui/buttons/BackButton';
import * as companyIncorporationAction from '../reduxStore/action';
import { connect } from 'react-redux';
import { API_CODE, API_ERROR_CODES } from '../../../constants/enum';
import { v4 as uuidv4 } from 'uuid';
import FileError from '../../common/FileError';
function UploadDocuments({
  uploadFile,
  prevStep,
  nextStep,
  isAddingOwner,
  setDocumentUrl,
  uploadedPassport,
  setPassportUploadResponse,
  setCurrentStackHolder,
  currentStackHolder
}) {
  const [uploadDocument, setUploadDocument] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fileSelection = async (acceptedFiles) => {
    setIsLoading(true);
    let selectedDocs = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );
    setUploadError(false);
    const formData = new FormData();
    formData.append('file', selectedDocs[0]);
    const uploadDocument = await uploadFile(formData);
    if (uploadDocument?.status === API_CODE.STATUS_200) {
      uploadPassportHandler(uploadDocument?.data?.data?.key, uploadDocument, acceptedFiles);
    } else {
      setIsLoading(false);
      setUploadError(true);
    }
  };
  const uploadPassportHandler = async (key, uploadDocument, acceptedFiles) => {
    const uploadedPassportResponse = await uploadedPassport(key);
    if (
      uploadedPassportResponse?.status === API_ERROR_CODES.STATUS_500 ||
      uploadedPassportResponse?.status === API_ERROR_CODES.STATUS_400
    ) {
      setUploadError(true);
    } else {
      setDocumentUrl(key);
      setCurrentStackHolder({
        ...currentStackHolder,
        passportDocument: {
          documentCode: uploadDocument?.data?.data?.key,
          documentName: acceptedFiles?.[0]?.name,
          DocumentType: 'Stack holder Passport ',
          isAvailableOnCompanyProfile: true
        },
        id: uuidv4()
      });
      setPassportUploadResponse(uploadedPassportResponse?.data?.data?.results);
      nextStep();
    }
    setIsLoading(false);
  };

  const back = () => {
    prevStep();
  };

  return (
    <div className="flex flex-col justify-between md:gap-8 gap-6 w-full h-full">
      <MDSubtitleText title="Owners and directors" fontWeight="text-bold" />
      <div>
        <XSText
          textColor="text-gray-500"
          title={`Upload the page of ${isAddingOwner ? 'their' : 'your'} passport that displays your photo`}
        />

        <div
          onClick={() => !uploadDocument && setUploadDocument(!uploadDocument)}
          className={`flex mt-2 justify-between ${!uploadDocument && 'cursor-pointer'} `}
        >
          <Dropzone
            accept={{ 'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png'], 'application/pdf': ['.pdf'] }}
            maxFiles={1}
            onDrop={(acceptedFiles) => fileSelection(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <section className="w-full">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isLoading ? (
                    <div className="flex items-center dropzone justify-center upload-document-div-loader">
                      <div className="flex flex-col items-center">
                        <StatrysLoader className="w-7 position-absolute" />
                        <XSText textColor="text-gray-450" title="Processing..." />
                      </div>
                    </div>
                  ) : uploadError ? (
                    <FileError className="md:w-[394px] w-auto cursor-pointer" />
                  ) : (
                    <div className="dropzone whitespace-pre-line">
                      <img src={FileUpload} className="file-upload mx-auto mb-2" />
                      <MSText
                        className="mb-2"
                        textAlign="text-center"
                        textColor="text-light-gray"
                        title={
                          <div>
                            Drop your file here or <span className="text-coral-500">browse</span>
                          </div>
                        }
                      />
                      <MSText
                        textAlign="text-center"
                        textColor="text-light-gray"
                        title={'Only Docx, Doc, PDF, JPG, and PNG\n files with max size of 10 MB.'}
                      />
                    </div>
                  )}
                </div>
              </section>
            )}
          </Dropzone>
        </div>

        <div className="bg-green-200 my-4 p-4 flex info-guid-note-container rounded-lg w-full">
          <img src={BulbIcon} alt="check icon" className="h-6 mr-4" />
          <div className="w-full">
            <MSText className="mb-2 mt-1" fontWeight="text-bold" title="Requirements for your passport copy" />
            <MSText
              title={
                <span>
                  • Passport must <span className="text-bold">not be expired</span>
                  <br />• Passport copy must be a <span className="text-bold">color copy</span>
                  <br />• All details on the page must be <span className="text-bold">clearly readable</span>
                </span>
              }
            />
          </div>
        </div>
      </div>
      <BackButton onClick={back} />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (uploadedImage) => dispatch(commonReduxActions.uploadDocument(uploadedImage)),
    uploadedPassport: (uploadedImage) => dispatch(companyIncorporationAction.uploadedPassport(uploadedImage))
  };
};

export default connect(null, mapDispatchToProps)(UploadDocuments);

UploadDocuments.propTypes = {
  openDynamicModal: PropTypes.bool,
  closeDynamicModal: PropTypes.any,
  uploadedPassport: PropTypes.func,
  uploadFile: PropTypes.func
};
