import { ENDPOINTS } from '../../../service/company-creation-service';
import Api from '../../../store/invoiceInterceptor';
import * as actionTypes from './actionTypes';

export const newCompanyRegistrationData = (newCompanyRegistrationData) => ({
  type: actionTypes.NEW_COMPANY_REGISTRATION,
  newCompanyRegistrationData: newCompanyRegistrationData
});

export const resetnewCompanyRegistration = (error) => ({
  type: actionTypes.RESET_NEW_COMPANY_REGISTRATION_SUCCESS,
  error: error
});

export const completedCompanyRegistrationData = (completedCompanyRegistrationData) => ({
  type: actionTypes.COMPLETED_COMPANY_REGISTRATION,
  completedCompanyRegistrationData: completedCompanyRegistrationData
});

export const addNewStakeHolder = (currentStakeHolder) => ({
  type: actionTypes.ADD_STAKEHOLDER_DATA,
  currentStakeHolder: currentStakeHolder
});

export const saveStakeHolderId = (stakeHolderInfo) => ({
  type: actionTypes.STAKEHOLDER_EDIT_DETAILS,
  stakeHolderInfo: stakeHolderInfo
});

export const stakeholderRemove = () => ({
  type: actionTypes.STAKEHOLDER_DETAILS_REMOVE
});

export const addAllStackHolders = (stakeHolder) => ({
  type: actionTypes.ADD_ALL_STAKEHOLDER,
  stakeHolder: stakeHolder
});

export const companyCreateRequestStart = () => ({ type: actionTypes.COMPANY_CREATE_REQUEST_START });
export const companyCreateRequestFail = (error) => ({ type: actionTypes.COMPANY_CREATE_REQUEST_FAILURE, error: error });
export const companyCreateRequestSuccess = (companyCreatePayload) => ({
  type: actionTypes.COMPANY_CREATE_REQUEST_SUCCESS,
  companyCreatePayload: companyCreatePayload
});
export const companyCreate = (companyCreatePayload) => {
  return async (dispatch) => {
    try {
      dispatch(companyCreateRequestStart());
      let companyCreateResponse = await Api.post(ENDPOINTS.CREATE, companyCreatePayload);
      dispatch(companyCreateRequestSuccess(companyCreateResponse?.data));
      return companyCreateResponse;
    } catch (error) {
      dispatch(companyCreateRequestFail(error.response));
      return error?.response;
    }
  };
};

export const existingCompanyCreateRequestStart = () => ({
  type: actionTypes.EXISTING_COMPANY_REGISTRATION_REQUEST_START
});
export const existingCompanyCreateRequestFail = (error) => ({
  type: actionTypes.EXISTING_COMPANY_REGISTRATION_FAILURE,
  error: error
});
export const existingCompanyCreateRequestSuccess = (existingCompanyCreatePayload) => ({
  type: actionTypes.EXISTING_COMPANY_REGISTRATION_SUCCESS,
  existingCompanyCreatePayload: existingCompanyCreatePayload
});
export const existingCompanyCreate = (existingCompanyCreatePayload) => {
  return async (dispatch) => {
    try {
      dispatch(existingCompanyCreateRequestStart());
      let existingCompanyCreateResponse = await Api.post(
        ENDPOINTS.EXISTING_REGISTRATION_COMPANY,
        existingCompanyCreatePayload
      );
      dispatch(existingCompanyCreateRequestSuccess(existingCompanyCreateResponse?.data));
      return existingCompanyCreateResponse;
    } catch (error) {
      dispatch(existingCompanyCreateRequestFail(error.response));
      return error?.response;
    }
  };
};

export const companyUpdateRequestStart = () => ({ type: actionTypes.COMPANY_UPDATE_REQUEST_START });
export const companyUpdateRequestFail = (error) => ({ type: actionTypes.COMPANY_UPDATE_REQUEST_FAILURE, error: error });
export const companyUpdateRequestSuccess = (companyUpdatePayload) => ({
  type: actionTypes.COMPANY_UPDATE_REQUEST_SUCCESS,
  companyUpdatePayload: companyUpdatePayload
});
export const companyUpdate = (companyUpdatePayload) => {
  return async (dispatch) => {
    try {
      dispatch(companyUpdateRequestStart());
      let companyUpdateResponse = await Api.post(ENDPOINTS.UPDATE, companyUpdatePayload);
      dispatch(companyUpdateRequestSuccess(companyUpdateResponse?.data));
      return companyUpdateResponse;
    } catch (error) {
      dispatch(companyUpdateRequestFail(error.response));
      return error?.response;
    }
  };
};

export const companyDetailsRequestStart = () => ({ type: actionTypes.COMPANY_DETAILS_REQUEST_START });
export const companyDetailsRequestFail = (error) => ({
  type: actionTypes.COMPANY_DETAILS_REQUEST_FAILURE,
  error: error
});
export const companyDetailsRequestSuccess = (companyDetails) => ({
  type: actionTypes.COMPANY_DETAILS_REQUEST_SUCCESS,
  companyDetails: companyDetails
});
export const companyDetails = (companyId) => {
  return async (dispatch) => {
    try {
      dispatch(companyDetailsRequestStart());
      let companyDetailsResponse = await Api.get(`${ENDPOINTS.DETAILS}?companyId=${companyId}`);
      dispatch(companyDetailsRequestSuccess(companyDetailsResponse?.data));
      return companyDetailsResponse;
    } catch (error) {
      dispatch(companyDetailsRequestFail(error.response));
      return error?.response;
    }
  };
};

export const uploadFileRequestStart = () => ({ type: actionTypes.UPLOAD_FILE_REQUEST_START });
export const uploadFileRequestFail = (error) => ({
  type: actionTypes.UPLOAD_FILE_REQUEST_FAILURE,
  error: error
});
export const uploadFileRequestSuccess = (fileData) => ({
  type: actionTypes.UPLOAD_FILE_REQUEST_SUCCESS,
  fileData: fileData
});
export const uploadPassport = (filePayload) => {
  let passportFileFormData = new FormData();
  passportFileFormData.append('companyId', filePayload?.companyId);
  passportFileFormData.append('passport', filePayload?.file?.[0]);
  return async (dispatch) => {
    try {
      dispatch(uploadFileRequestStart());
      let uploadFileResponse = await Api.put(ENDPOINTS.UPLOAD_FILE, passportFileFormData);
      uploadFileResponse.data['previewFile'] = filePayload?.file;
      dispatch(uploadFileRequestSuccess(uploadFileResponse?.data));
      return uploadFileResponse;
    } catch (error) {
      dispatch(uploadFileRequestFail(error.response));
      return error?.response;
    }
  };
};

export const paymentRequestStart = () => ({ type: actionTypes.PAYMENT_REQUEST_START });
export const paymentRequestFail = (error) => ({
  type: actionTypes.PAYMENT_REQUEST_FAILURE,
  error: error
});
export const paymentRequestSuccess = (paymentData) => ({
  type: actionTypes.PAYMENT_REQUEST_SUCCESS,
  paymentData: paymentData
});
export const createPayment = (paymentPayload) => {
  return async (dispatch) => {
    try {
      dispatch(paymentRequestStart());
      let paymentResponse = await Api.post(ENDPOINTS.CREATE_PAYMENT, paymentPayload);
      dispatch(paymentRequestSuccess(paymentResponse?.data));
      return paymentResponse;
    } catch (error) {
      dispatch(paymentRequestFail(error.response));
      return error?.response;
    }
  };
};

export const deleteRequestStart = () => ({ type: actionTypes.DELETE_STAKHOLDER_REQUEST_START });
export const deleteRequestFail = (error) => ({
  type: actionTypes.DELETE_STAKHOLDER_FAILURE,
  error: error
});
export const deleteRequestSuccess = (payload) => ({
  type: actionTypes.DELETE_STAKHOLDER_SUCCESS,
  deletedData: payload
});
export const deleteStakeHolder = (Payload) => {
  return async (dispatch) => {
    try {
      dispatch(deleteRequestStart());
      let deleteStakeHolderResponse = await Api.post(ENDPOINTS.DELETE_STAKHOLDER, Payload);
      dispatch(deleteRequestSuccess(deleteStakeHolderResponse?.data));
      return deleteStakeHolderResponse;
    } catch (error) {
      dispatch(deleteRequestFail(error.response));
      return error?.response;
    }
  };
};
