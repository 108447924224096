import React, { useState } from 'react';
import XSText from '../../components/ui/typography/XSText';
import TextInput from '../../components/ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import { API_CODE, CHAR_CODE, REGEX } from '../../constants/enum';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ValidationMessage from '../../components/ui/ValidationMessage';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import { Link } from 'gatsby';
import { handleLogin } from '../../components/authService/auth';
import { connect } from 'react-redux';
import * as LoginActions from './reduxStore/action';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DynamicModal from '../ui/modal/DynamicModal';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import BaseText from '../ui/typography/BaseText';
import IconPuzzleBlock from '../../images/icons/puzzle-block.svg';
import IconMessage from '../../images/icons/message.svg';
import IconFileCopyRight from '../../images/icons/file-copyright.svg';
import LoginModalImg from '../../images/graphics/login/invoice-login-modal-img.png';
import BulbIcon from '../../images/icons/bulb.svg';
const invoice = 'INVOICE';

function LoginForm({ login, activeLoginForm }) {
  const emailRegex = REGEX.EMAIL_REGEX;
  const [showPassword, setShowPassword] = useState();
  const [error, setError] = useState(false);
  const { handleSubmit, register, errors } = useForm();
  const [email, setEmail] = useState(null);
  const [openInvoiceLoginModal, setOpenInvoiceLoginModal] = useState(false);
  const isLoading = useSelector((state) => state.loginReucer?.loading);
  const PasswordClicked = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    setEmail(data?.email);
    let payload = {
      email: data?.email.trim(),
      password: data?.password.trim(),
      activeTab: activeLoginForm
    };
    if (typeof window !== `undefined`) {
      await window?.heap?.identify(data?.email);
    }
    const loginResponse = await login(payload);
    if (loginResponse?.status === API_CODE.STATUS_200) {
      handleLogin(loginResponse?.data?.data?.token, loginResponse?.data?.data, activeLoginForm);
    } else {
      activeLoginForm === invoice && setOpenInvoiceLoginModal(true);
      setError(true);
    }
  };
  const handleKeyPress = (event) => {
    if (event.keyCode === CHAR_CODE.ENTER_KEY_CODE) {
      handleSubmit(onSubmit)();
    }
  };
  return (
    <form id="login-form" className="container-login-form " onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full mt-4">
        <TextInput
          margin="mb-4"
          name="email"
          label="Email Address"
          variant="filled"
          inputRef={register({
            required: true,
            pattern: {
              value: emailRegex,
              message: 'Please fill in a valid Email Address'
            }
          })}
          error={errors?.email ? true : false}
          helperText={errors?.email && <ValidationMessage title={'Please fill in a valid Email Address'} />}
        />

        <TextInput
          type={showPassword ? 'text' : 'password'}
          label="Password"
          name="password"
          onRightSideAdornment={PasswordClicked}
          endAdornment={showPassword ? <Visibility /> : <VisibilityOff />}
          textFieldContainerClass="mt-4"
          inputRef={register({
            required: true
          })}
          onKeyDown={handleKeyPress}
          error={errors?.password || error ? true : false}
          helperText={errors?.password && <ValidationMessage title={'Please fill in a valid Password'} />}
        />
        {error && (
          <ValidationMessage
            classNameForText="md:w-10/12 w-full"
            title="The email address or password does not match."
          />
        )}
        <PrimaryButton
          id="login-form-btn"
          bgColor="bg-coral-500"
          className="button w-full mt-8 mb-4"
          isLoading={isLoading}
          caption="Login"
          onClick={handleSubmit(onSubmit)}
        />
        <XSText
          title={
            <div>
              Forgot your password?{' '}
              <Link
                to="/confirm-email-account"
                state={{
                  email: email
                }}
              >
                <span className="text-coral-500">Reset it here</span>
              </Link>
            </div>
          }
        />
        {activeLoginForm === invoice ? (
          <div className="flex items-center mt-8">
            <XSText
              title={
                <>
                  <span>
                    Don’t have an <span className="text-bold">Invoicing account</span> yet?
                  </span>
                  <span>
                    <Link to="/signup" className="ml-1 text-coral-500">
                      Create an account
                    </Link>
                  </span>
                </>
              }
            />
          </div>
        ) : (
          <div className="p-4 bg-green-200 flex gap-4 items-start mt-4 rounded-lg">
            <img src={BulbIcon} alt="BulbIcon" />
            <XSText
              title={
                <div>
                  Access is exclusively for companies using Statrys corporate services in Hong Kong. Register your Hong
                  Kong company with Statrys today.
                  <Link
                    to={`${process.env.MARKETING_SITE_URL}/hong-kong-company-registration`}
                    className="text-bold text-green-500"
                  >
                    {' '}
                    Discover what our package has to offer.
                  </Link>
                </div>
              }
            />
          </div>
        )}
      </div>
      <DynamicModal
        openDynamicModal={openInvoiceLoginModal}
        closeDynamicModal={() => setOpenInvoiceLoginModal()}
        minWidth="md"
        maxWidth="md"
      >
        <div className="flex">
          <div className="w-full relative z-10 whitespace-pre-line ml-2">
            <MDSubtitleText title={`Oops! It seems you don’t have an\n Invoice management account yet.`} />
            <MDSubtitleText
              fontWeight="text-bold"
              className="my-6"
              title={'Step up to a real invoice\n management solution.'}
            />
            <div className="flex gap-5 items-center">
              <img src={IconFileCopyRight} width="40" alt="IconFileCopyRight" />
              <BaseText
                title={
                  <div>
                    <span className="text-bold">Create</span>, save, clone invoices - all in 2 clicks
                  </div>
                }
              />
            </div>
            <div className="flex gap-5 items-center my-4">
              <img src={IconMessage} width="40" alt="IconMessage" />
              <BaseText
                title={
                  <div>
                    <span className="text-bold">Send </span>invoices and track openings
                  </div>
                }
              />
            </div>
            <div className="flex gap-5 items-center">
              <img src={IconPuzzleBlock} width="40" alt="IconPuzzleBlock" />
              <BaseText
                title={
                  <div>
                    <span className="text-bold">Import </span>supplier invoices with OCR
                  </div>
                }
              />
            </div>
            <div className="flex gap-2 mt-14">
              <PrimaryButton
                id="login-form-create-account"
                urlLink="/signup"
                target="_blank"
                fontWeight="text-bold"
                caption="Create account"
              />
              <PrimaryButton
                id="login-form-check-feature-btn"
                urlLink={`${process.env.MARKETING_SITE_URL}/invoices`}
                fontWeight="text-bold"
                bgColor="bg-white"
                color="text-coral-500"
                target="_blank"
                caption="Check the features"
              />
            </div>
          </div>
          <div className="w-full">
            <div className="yellow-background-shadow-effect md:block hidden"></div>
            <div className="overflow-hidden  absolute">
              <img src={LoginModalImg} className="-mb-9 z-inherit" alt="login modal img" />
            </div>
          </div>
        </div>
      </DynamicModal>
    </form>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (loginPayload) => dispatch(LoginActions.login(loginPayload))
  };
};

export default connect(null, mapDispatchToProps)(LoginForm);
LoginForm.propTypes = {
  login: PropTypes.func
};
