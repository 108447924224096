import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import MSText from '../../ui/typography/MSText';
import FileUpload from '../../../images/graphics/upload-doc.png';
import { getFileExtension, stringTruncate } from '../../../helper/helpers';
import { FILE_EXTENTION_TYPE, FILE_UPLOAD_TYPE } from '../../../constants/enum';
import FileIcon from '../../../images/icons/file-icon-1.svg';
import CloseIcon from '../../../images/icons/close-icon.svg';

function UploadFile({ saveImage, fileSelection, uploadError, selectedImage, acceptedFile, handleRemoveFile }) {
  const [uploadLogo, setUploadLogo] = useState(false);
  const handleSelectingFiles = (event) => {
    if (selectedImage.length > 0) {
      event.stopPropagation();
    }
  };
  return (
    <div>
      <div
        onClick={() => !saveImage && !uploadLogo && setUploadLogo(!uploadLogo)}
        className={`mt-2 ${!saveImage && !uploadLogo && 'cursor-pointer'} w-full`}
      >
        <Dropzone maxFiles={1} onDrop={(acceptedFiles) => fileSelection(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ onClick: (event) => handleSelectingFiles(event) })}>
                <input {...getInputProps()} />
                {selectedImage ? (
                  selectedImage[0].type === FILE_UPLOAD_TYPE.PNG ||
                  selectedImage[0].type === FILE_UPLOAD_TYPE.JPEG ||
                  selectedImage[0].type === FILE_UPLOAD_TYPE.URL ||
                  selectedImage[0].type === FILE_UPLOAD_TYPE.PDF ||
                  selectedImage[0].type === FILE_UPLOAD_TYPE.JPG ||
                  getFileExtension(selectedImage[0].name) === FILE_EXTENTION_TYPE.PNG ||
                  getFileExtension(selectedImage[0].name) === FILE_EXTENTION_TYPE.JPEG ||
                  getFileExtension(selectedImage[0].name) === FILE_EXTENTION_TYPE.JPG ||
                  getFileExtension(selectedImage[0].name) === FILE_EXTENTION_TYPE.PDF ||
                  getFileExtension(selectedImage[0].name) === FILE_EXTENTION_TYPE.DOCX ||
                  getFileExtension(selectedImage[0].name) === FILE_EXTENTION_TYPE.DOC ? (
                    <div className="flex justify-between px-4 py-4 mt-4 border rounded-lg items-center">
                      <div className="flex items-center">
                        <div className="mr-4">
                          <img src={FileIcon} alt="address file" />
                        </div>
                        <MSText
                          title={
                            selectedImage?.[0]?.name
                              ? stringTruncate(selectedImage?.[0]?.name, 20)
                              : stringTruncate(acceptedFile?.name, 20)
                          }
                        />
                      </div>
                      <div>
                        <img
                          className="cursor-pointer"
                          src={CloseIcon}
                          alt="close-icon"
                          onClick={() => handleRemoveFile(acceptedFile)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="dropzone-upload-file flex items-center whitespace-pre-line cursor-pointer">
                      <img src={FileUpload} className="w-4 h-4 mr-4 ml-5" />
                      <div>
                        <MSText
                          textColor="text-light-gray"
                          title={
                            <div>
                              <span className="text-coral-500">Click to upload</span> or drag and drop
                            </div>
                          }
                        />
                        <MSText textColor="text-light-gray" title={'Doc, Docx, PDF, JPG, and PNG (max 4 MB)'} />
                      </div>
                    </div>
                  )
                ) : (
                  <div className="dropzone-upload-file flex items-center whitespace-pre-line cursor-pointer">
                    <img src={FileUpload} className="w-4 h-4 mr-4 ml-5" />
                    <div>
                      <MSText
                        textColor="text-light-gray"
                        title={
                          <div>
                            <span className="text-coral-500">Click to upload</span> or drag and drop
                          </div>
                        }
                      />
                      <MSText textColor="text-light-gray" title={'Doc, Docx, PDF, JPG, and PNG (max 10 MB)'} />
                    </div>
                  </div>
                )}
              </div>
              {uploadError ? (
                <MSText
                  className="mt-2"
                  textColor="text-coral-500"
                  title={'Only PDF, JPG, and PNG files with max size of 10MB'}
                />
              ) : null}
            </section>
          )}
        </Dropzone>
      </div>
    </div>
  );
}

export default UploadFile;
