import React, { useState } from 'react';
import TextInput from '../../ui/inputs/TextInput';
import MSText from '../../ui/typography/MSText';
import XSText from '../../ui/typography/XSText';
import MultiTextInput from '../../ui/inputs/MultiTextInput';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import { API_CODE, BUSINESS_EXPERIENCE, EXPERIENCE_VALUE } from '../../../constants/enum';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import BackButton from '../../ui/buttons/BackButton';
import AlertTriangle from '../../../images/icons/alert-triangle.svg';
import EditOwnerDirectorDetails from './EditOwnerDetails';
import { useForm } from 'react-hook-form';
import * as CompanyAction from '../../../components/companyIncorporation/reduxStore/action';
import { connect } from 'react-redux';
const experienceButtonArray = [
  {
    value: EXPERIENCE_VALUE.ZERO
  },
  {
    value: EXPERIENCE_VALUE.ONE
  },
  {
    value: EXPERIENCE_VALUE.TWO_TO_FIVE
  },
  {
    value: EXPERIENCE_VALUE.FIVE_TO_TEN
  },
  {
    value: EXPERIENCE_VALUE.TEN_PLUS
  }
];

const businessExperienceArray = [
  {
    value: BUSINESS_EXPERIENCE?.BUSINESS_OWNER
  },
  {
    value: BUSINESS_EXPERIENCE.EMPLOYEE
  },
  {
    value: BUSINESS_EXPERIENCE.CONSULTANT
  },
  {
    value: BUSINESS_EXPERIENCE.FREELANCER
  }
];
function BusinessDetailAndExperience({
  prevStep,
  nextStep,
  isOwnerDirectorEdit,
  setCurrentStackHolder,
  currentStackHolder,
  stackHolderPayload,
  setStackHolderPayload,
  currentEditStackHolderDetails,
  companyAllInformation,
  updateStakeHolder,
  updateCompanyIncorporation,
  setIsOwnerDirectorEdit,
  setDocumentUrl,
  getCompanyDetails
}) {
  const [experience, setExperience] = useState(
    isOwnerDirectorEdit?.isEdit ? currentEditStackHolderDetails?.experience : currentStackHolder?.experience
  );
  const [businessExperience, setBusinessExperience] = useState(
    isOwnerDirectorEdit?.isEdit
      ? currentEditStackHolderDetails?.businessExperience
      : currentStackHolder?.businessExperience
  );
  const [isExperience, setIsExperience] = useState(false);
  const [isBusinessExperience, setIsBusinessExperience] = useState(false);
  const { register, handleSubmit } = useForm();

  const back = () => {
    prevStep();
  };

  const handleExperience = (value) => {
    setExperience(value);
    setIsExperience(false);
  };

  const handleBusinessExperience = (value) => {
    setBusinessExperience(value);
    setIsBusinessExperience(false);
  };

  const onSubmit = async (data) => {
    if (experience && businessExperience) {
      setCurrentStackHolder({
        ...currentStackHolder,
        experience: experience,
        businessExperience: businessExperience,
        linkedProfileUrl: data?.linkedinLink,
        description: data?.description
      });
      let newCurrentPayload = {
        ...currentStackHolder,
        experience: experience,
        businessExperience: businessExperience,
        linkedProfileUrl: data?.linkedinLink,
        description: data?.description
      };
      if (isOwnerDirectorEdit?.isEdit) {
        let payload = {
          companyId: companyAllInformation?._id,
          stakeholderId: isOwnerDirectorEdit?.details?.id,
          ...newCurrentPayload
        };

        const updateStakeHolderResponse = await updateStakeHolder(payload);
        if (updateStakeHolderResponse?.status === API_CODE.STATUS_200) {
          setStackHolderPayload([]);
          setIsOwnerDirectorEdit({ ...isOwnerDirectorEdit, isEdit: false });
          setDocumentUrl('');
          getCompanyDetails(sessionStorage.getItem('companyId'));
          nextStep();
          setCurrentStackHolder({});
        }
      } else {
        const bothStackHolder = companyAllInformation?.stakeholder.concat(newCurrentPayload);
        const FinalOwnerStackHolder = bothStackHolder.filter((holder) => holder?.passportDocument);
        let finalPayload = {
          stakeholder: FinalOwnerStackHolder,
          companyId: companyAllInformation?._id
        };
        const addNewStakeHolders = await updateCompanyIncorporation(finalPayload);
        if (addNewStakeHolders?.status === API_CODE.STATUS_200) {
          setStackHolderPayload([]);
          setIsOwnerDirectorEdit({ ...isOwnerDirectorEdit, isEdit: false });
          nextStep();
          setCurrentStackHolder({});
          setDocumentUrl('');
        }
      }
      const isAlreadyExisted = stackHolderPayload.filter((holder) => holder?.id !== currentStackHolder?.id);
      setStackHolderPayload([...isAlreadyExisted, newCurrentPayload]);
    }
    if (!experience) {
      setIsExperience(true);
    }
    if (!businessExperience) {
      setIsBusinessExperience(true);
    }
  };

  return (
    <div className="flex flex-col md:gap-8 gap-6 w-full h-full">
      <MDSubtitleText title="Owners and directors" fontWeight="text-bold" />
      {isOwnerDirectorEdit?.isEdit && <EditOwnerDirectorDetails data={currentEditStackHolderDetails} />}
      <div className="md:w-4/6 w-full md:mt-6 mt-0">
        <XSText title="How many years of experience do you have in the company business?" />
        <div className="flex md:flex-row flex-wrap mt-2 gap-2">
          {experienceButtonArray?.map((experienceButton, index) => {
            return (
              <PrimaryButton
                key={index}
                className={
                  experience != experienceButton?.value ? 'bussinece-experince-selector' : 'active-selected-experience'
                }
                bgColor="bg-white"
                color="text-gray-300"
                caption={experienceButton?.value}
                onClick={() => handleExperience(experienceButton?.value)}
              />
            );
          })}
        </div>
        {isExperience ? (
          <div className="ml-5 mt-2 flex">
            <img src={AlertTriangle} alt="alert triangle" />
            <MSText className="ml-2" textColor="select-input-error" title="Please select a valid experience" />
          </div>
        ) : null}
        <XSText className="mt-6" title="How did you acquire experience in the business?" />
        <div className="flex flex-wrap mt-2 gap-2">
          {businessExperienceArray?.map((experience, index) => {
            return (
              <PrimaryButton
                key={index}
                linkClass="md:w-auto w-full"
                className={`${businessExperience === experience?.value && 'active-selcted'} bussinece-experince`}
                bgColor="bg-white"
                caption={experience?.value}
                color="text-gray-300"
                onClick={() => handleBusinessExperience(experience?.value)}
              />
            );
          })}
        </div>

        {isBusinessExperience ? (
          <div className="ml-5 mt-2 flex">
            <img src={AlertTriangle} alt="alert triangle" />
            <MSText
              className="ml-2"
              textColor="select-input-error"
              title={'Please select a valid business experience'}
            />
          </div>
        ) : null}
        <XSText className="mt-6 mb-2" title="Linkedin profile link (if available)" />
        <form id="experience-form" onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            name="linkedinLink"
            label="Linkedin profile (Optional)"
            textFieldContainerClass="w-full"
            defaultValue={
              isOwnerDirectorEdit?.isEdit
                ? currentEditStackHolderDetails?.linkedProfileUrl
                : currentStackHolder?.linkedProfileUrl
            }
            inputRef={register({
              required: {
                value: false
              }
            })}
          />

          <XSText className="mt-6" title="Specify your current occupation" />
          <MultiTextInput
            inputContainerClass="mt-4"
            inputRef={register({
              required: {
                value: false
              }
            })}
            name="description"
            defaultValue={
              isOwnerDirectorEdit?.isEdit ? currentEditStackHolderDetails?.description : currentStackHolder?.description
            }
            rows={4}
            label="Description"
          />
        </form>
      </div>
      <div className="flex md:flex-row flex-col-reverse justify-between">
        <BackButton onClick={back} className="md:w-[114px] w-full" />
        <PrimaryButton
          caption="Continue"
          color="text-white"
          className="w-full"
          isBorderRequired={true}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateStakeHolder: (companyId) => dispatch(CompanyAction.updateStakholder(companyId)),
    updateCompanyIncorporation: (payload) => dispatch(CompanyAction.updateCompanyIncorporation(payload)),
    getCompanyDetails: (companyId) => dispatch(CompanyAction.getCompanyIncorporation(companyId))
  };
};

export default connect(null, mapDispatchToProps)(BusinessDetailAndExperience);
