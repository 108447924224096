export const FILE_UPLOAD_REQUEST_START = 'FILE_UPLOAD_REQUEST_START';
export const FILE_UPLOAD_REQUEST_SUCCESS = 'FILE_UPLOAD_REQUEST_SUCCESS';
export const FILE_UPLOAD_REQUEST_FAILURE = 'FILE_UPLOAD_REQUEST_FAILURE';

export const CHECK_SEND_EMAIL_REQUEST_START = 'CHECK_SEND_EMAIL_REQUEST_START';
export const CHECK_SEND_EMAIL_REQUEST_SUCCESS = 'CHECK_SEND_EMAIL_REQUEST_SUCCESS';
export const CHECK_SEND_EMAIL_REQUEST_FAILURE = 'CHECK_SEND_EMAIL_REQUEST_FAILURE';

export const CHECK_VALIDATE_SEND_EMAIL_REQUEST_START = 'CHECK_VALIDATE_SEND_EMAIL_REQUEST_START';
export const CHECK_VALIDATE_SEND_EMAIL_REQUEST_SUCCESS = 'CHECK_VALIDATE_SEND_EMAIL_REQUEST_SUCCESS';
export const CHECK_VALIDATE_SEND_EMAIL_REQUEST_FAILURE = 'CHECK_VALIDATE_SEND_EMAIL_REQUEST_FAILURE';

export const GET_COUNTRY_REQUEST_START = 'GET_COUNTRY_REQUEST_START';
export const GET_COUNTRY_REQUEST_SUCCESS = 'GET_COUNTRY_REQUEST_SUCCESS';
export const GET_COUNTRY_REQUEST_FAILURE = 'GET_COUNTRY_REQUEST_FAILURE';

export const UPLOAD_DOCUMENT_REQUEST_START = 'UPLOAD_DOCUMENT_REQUEST_START';
export const UPLOAD_DOCUMENT_REQUEST_SUCCESS = 'UPLOAD_DOCUMENT_REQUEST_SUCCESS';
export const UPLOAD_DOCUMENT_REQUEST_FAILURE = 'UPLOAD_DOCUMENT_REQUEST_FAILURE';

export const PREVIEW_DOCUMENT_REQUEST_START = 'PREVIEW_DOCUMENT_REQUEST_START';
export const PREVIEW_DOCUMENT_REQUEST_SUCCESS = 'PREVIEW_DOCUMENT_REQUEST_SUCCESS';
export const PREVIEW_DOCUMENT_REQUEST_FAILURE = 'PREVIEW_DOCUMENT_REQUEST_FAILURE';
