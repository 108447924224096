import React, { useState } from 'react';
import IconPencil from '../../../images/icons/company-incorporation/edit-pencil-icon.svg';
import TrashIcon from '../../../images/icons/trash-icon.svg';
import '../../../styles/cards.scss';
import PropTypes from 'prop-types';
import { MUI_COLORS, COMPANY_SETUP_STEP, COMPANY_SETUP_SIDEBAR_STEP, CONSTANT_NUMBER } from '../../../constants/enum';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import XSText from '../../ui/typography/XSText';
import MSText from '../../ui/typography/MSText';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import Avatar from '../../ui/Avatar';
import Trash from '../../../images/icons/customSvg/Trash';
import InfoPopUp from '../../ui/InfoPopUp';
export default function OwnersCard({
  deleteCard,
  id,
  isLocal,
  setIsOwnerDirectorEdit,
  numberOfStakholder,
  setStep,
  setActiveStep,
  isOwnerDirectorEdit,
  setSubStep,
  allData,
  setPassportUploadResponse
}) {
  const { firstName, lastName, isMainUser } = allData;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const deleteAndClose = (id, isLocal) => {
    deleteCard(id, isLocal);
    handleClose();
  };

  const editOwnerDirector = (id, isLocal) => {
    setIsOwnerDirectorEdit({ ...isOwnerDirectorEdit, isEdit: true, details: { id, isLocal } });
    setStep(COMPANY_SETUP_STEP?.OWNERS_AND_DIRECTOR_SELECTOR);
    setActiveStep(COMPANY_SETUP_SIDEBAR_STEP?.OWNERS_AND_DIRECTORS);
    setSubStep(isMainUser ? CONSTANT_NUMBER.ONE : CONSTANT_NUMBER.TWO);
    setPassportUploadResponse('');
  };

  const avatarText = firstName && firstName?.charAt(0) + lastName?.charAt(0);
  return (
    <div className="owners-card">
      <div className="flex justify-between p-4">
        <div className="flex gap-4">
          <Avatar avatarName={avatarText} />
          <div>
            <XSText
              fontWeight="text-bold mb-2 capitalize"
              title={firstName && lastName && firstName + ' ' + lastName}
            />
            <div className="flex gap-2">
              <MSText className="card-type-text flex items-center" title={isMainUser ? 'Yourself' : 'Individual'} />
              {allData?.isShareholder ? (
                <MSText
                  className="role-owner flex items-center justify-center px-2 py-1"
                  fontWeight="text-bold"
                  title="Owner"
                />
              ) : null}
              {allData?.isDirector ? (
                <MSText
                  className="role-director flex items-center justify-center px-2 py-1"
                  fontWeight="text-bold"
                  title="Director"
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          {numberOfStakholder > 1 && isMainUser ? (
            <div className="cursor-pointer mr-4 relative">
              <InfoPopUp
                popUpClass="mt-[-116px] ml-[-160px] w-[335px]"
                info="You can only delete yourself when you are the only shareholder or director"
                alwaysShow={true}
              >
                <Trash fill={MUI_COLORS?.DISABLE} />
              </InfoPopUp>
            </div>
          ) : (
            <div className="cursor-pointer mr-4" onClick={() => setOpen(true)}>
              <Trash />
            </div>
          )}

          <div className="cursor-pointer" onClick={() => editOwnerDirector(id, isLocal)}>
            <img src={IconPencil} alt={'Pencil'} className="w-4 h-4" />
          </div>
        </div>
      </div>
      <div>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle>
          <DialogContent>
            <div className="flex mb-6">
              <img src={TrashIcon} alt="IconBin" className="w-12 h-12 mr-4" />
              <MDSubtitleText
                className="md:whitespace-pre-line"
                fontWeight="text-bold"
                title={`Are you sure you want to \ndelete this ${firstName}?`}
              />
            </div>

            <div className="flex flex-col md:flex-row items-center md:items-start md:gap-6 gap-2">
              <PrimaryButton
                className="capitalize w-32 md:my-4 onboarding-button"
                bgColor="bg-white"
                color="text-coral-500"
                fontSize="text-base"
                caption="Cancel"
                onClick={handleClose}
              />
              <PrimaryButton
                className="capitalize w-32 md:my-4 onboarding-button"
                fontSize="text-base"
                caption="Delete"
                onClick={() => deleteAndClose(id, isLocal)}
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

OwnersCard.propTypes = {
  directors: PropTypes.bool,
  setPayload: PropTypes.func,
  type: PropTypes.string,
  deleteCard: PropTypes.func,
  cardCode: PropTypes.string,
  cardType: PropTypes.string,
  relationshipType: PropTypes.string
};
