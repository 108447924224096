import { Divider } from '@mui/material';
import React, { useState, useEffect } from 'react';
import BaseText from '../../ui/typography/BaseText';
import MSText from '../../ui/typography/MSText';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import XMSText from '../../ui/typography/XMSText';
import Iconify from '../../Iconify';
import { downloadImage, getFileExtension } from '../../../helper/helpers';
import { FILE_EXTENTION_TYPE } from '../../../constants/enum';
import { fDate } from '../../../utils/formatTime';
import ShareDocumentByLink from '../common/ShareDocumentByLink';
import '../../../styles/pages/company.scss';
import * as commonReduxActions from '../../commonReduxStore/reduxStore/action';
import { connect } from 'react-redux';
import StatrysLoader from '../../ui/loaders/StatrysLoader';

function MailRoomDetails({ viewdata, documentURLQuery, companyId, previewDocument }) {
  const [numPages, setNumPages] = useState(null);
  const [open, setOpen] = useState(false);
  const [previewDocumentUrl, setPreviewDocumentUrl] = useState(null);
  const [previewDocumentLoading, setPreviewDocumentLoading] = useState(true);
  const handlePreviewDocument = async () => {
    if (viewdata?.documentCode || viewdata?.documentUrl) {
      const previewDocumentResponse = await previewDocument({
        [`${viewdata?.documentCode ? 'code' : 'documentUrl'}`]: viewdata?.documentCode
          ? viewdata?.documentCode
          : viewdata?.documentUrl,
        newVersion: viewdata?.documentCode ? true : false
      });
      setPreviewDocumentUrl(previewDocumentResponse?.data);
      setPreviewDocumentLoading(false);
    }
  };
  useEffect(() => {
    handlePreviewDocument();
  }, [viewdata?.documentCode, viewdata?.documentUrl]);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const options = {
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="w-8/12">
      <div className="bg-white pt-6 rounded-t-xl pb-6 border-gray-400 border">
        <div className="flex justify-between items-center px-6">
          <div>
            <BaseText fontSize="text-xl" fontWeight="text-bold" title={viewdata?.documentName} />
            <XMSText
              className="mt-2"
              textColor="text-gray-450"
              title={viewdata?.uploadedDate ? fDate(viewdata?.uploadedDate) : viewdata?.uploadedDate}
            />
          </div>

          <div>
            {/* <PrimaryButton
              caption={
                <div className={`flex justify-center gap-2 items-center`}>
                  <Iconify icon={'ep:delete'} />
                </div>
              }
              type="small"
              bgColor="bg-customBg-mainBg"
              className="company-white-button-without-border mr-2"
            /> */}
            <PrimaryButton
              id="mail-room-share-btn"
              caption={
                <div className={`flex justify-center gap-2 items-center`}>
                  <Iconify icon={'mdi:share'} />
                </div>
              }
              onClick={() => setOpen(true)}
              type="small"
              bgColor="bg-customBg-mainBg"
              className="company-white-button-without-border mr-2"
            />
            <PrimaryButton
              id="mail-room-download-btn"
              caption={
                <div className={`flex justify-center gap-2 items-center`}>
                  <Iconify icon={'lucide:download-cloud'} />
                  <span>Download</span>
                </div>
              }
              onClick={() => downloadImage(previewDocumentUrl, viewdata?.documentName)}
              type="small"
              bgColor="bg-white"
              className="company-white-button"
            />
          </div>
        </div>
      </div>
      <Divider />
      {previewDocumentLoading ? (
        <StatrysLoader />
      ) : (
        <div className="w-full">
          {getFileExtension(previewDocumentUrl) === FILE_EXTENTION_TYPE.PNG ||
          getFileExtension(previewDocumentUrl) === FILE_EXTENTION_TYPE.JPEG ||
          getFileExtension(previewDocumentUrl) === FILE_EXTENTION_TYPE.JPG ? (
            <>
              <div className="w-full box-shadow company-invoice-img-container">
                <img className="w-full" src={previewDocumentUrl} alt="Uploaded Image" />
              </div>
            </>
          ) : (
            <>
              <Document file={previewDocumentUrl} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                <div className="company-pdf-container">
                  {Array.from(new Array(numPages), (el, index) => (
                    <>
                      <div className="flex justify-end relative">
                        <MSText
                          className="px-2 rounded-md bg-purple-500 line-height-22 z-10 company-page-badge"
                          textColor="text-white"
                          title={`Page ${index + 1} of ${numPages}`}
                        />
                      </div>
                      <div className="mb-6 company-mailroom-pdf-rendering">
                        <Page scale={1} key={`page_${index + 1}`} pageNumber={index + 1} />
                      </div>
                    </>
                  ))}
                </div>
              </Document>
            </>
          )}
        </div>
      )}
      <ShareDocumentByLink
        openModal={open}
        closeDynamicModal={() => setOpen(false)}
        id={companyId}
        documentURLQuery={documentURLQuery}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    previewDocument: (uploadedImage) => dispatch(commonReduxActions.previewDocument(uploadedImage))
  };
};

export default connect(null, mapDispatchToProps)(MailRoomDetails);
