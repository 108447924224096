import React from 'react';
import FileUpload from '../../images/graphics/un-supported.png';
import MSText from '../ui/typography/MSText';

const FileError = ({ className }) => {
  return (
    <div className={`bg-yellow-300 rounded-xl border shadow py-6 ${className}`}>
      <img src={FileUpload} className="w-[112px] h-[88px] mx-auto mb-2" />
      <MSText
        className="mb-2"
        textAlign="text-center"
        fontWeight="text-bold"
        textColor="text-gray-500"
        title="Unsupported file format!"
      />
      <MSText
        className="mb-2 whitespace-pre-line"
        textAlign="text-center"
        textColor="text-light-gray"
        title={'Please upload a file in PDF, JPG, or PNG \nformat, up to a maximum size of 10 MB.'}
      />
      <MSText
        className="mb-2"
        textAlign="text-center"
        textColor="text-light-gray"
        title={
          <div>
            Drop your file here or <span className="text-coral-500">browse</span>
          </div>
        }
      />
    </div>
  );
};

export default FileError;
