export const INVOICES_API = 'example/api';
export const ONBOARDING_API = 'onboarding-management/v1/users';
const V1 = 'v1';
const V2 = 'v2';
export const ENDPOINTS = {
  GET_INVOICE_DETAILS: `invoice/detail`,
  GET_INVOICE_LIST: `invoice/list`,
  SIGNUP: 'invoice/signUp',
  LOGIN: 'invoice/login',
  COMPANY_LOGIN: 'company/login',
  CONFIRM_EMAIL: 'invoice/confirm_email',
  VERIFY_OTP: 'invoice/verifyOtp',
  FORGOT_PASSWORD: 'invoice/forgotPassword',
  RESET_PASSWORD: 'invoice/resetPassword',
  RESEND_OTP: 'invoice/resendOtp',
  CREATE_CONTACT: 'contact/create',
  CONTACT_LIST: 'contact/list',
  ALL_CONTACTS: '/invoice/allContacts',
  EDIT_CONTACT: 'contact/update',
  DELETE_CONTACT: 'contact/delete',
  UPLOAD: 'uploadFile',
  VIEW: 'contact/detail',
  UPDATE_COMPANY_DETAILS: 'invoice/updateCompanyDetails',
  USER_PROFILE: 'invoice/userProfile',
  COMPANY_USER_PROFILE: 'company/user-profile',
  CHANGE_PASSWORD: 'invoice/changePassword',
  COMPANY_DETAILS: 'invoice/companyDetails',
  CREATE_INVOICE: 'invoice/create',
  DELETE_INVOICE: 'invoice/delete',
  EDIT_INVOICE: 'invoice/edit',
  GET_PUBLIC_INVOICE: 'invoice',
  SEND_EMAIL_INVOICE: 'invoice/sendEmail',
  PUBLIC_URL_EMAIL: '/invoice/publicUrlEmail',
  INVOICE_COUNT: '/invoice/count',
  CURRENCY_PREFERENCE: '/invoice/userSettings',
  INVOICE_UPLOAD_PDF: 'invoiceUploadPdf',
  CREATE_SUPPLIER: 'supplier/create',
  UPDATE_SUPPLIER: 'supplier/update',
  DELETE_SUPPLIER: 'supplier/delete',
  GET_SUPPLIER_BY_ID: 'supplier/detail',
  GET_SUPPLIER_LIST: 'supplier/list',
  INVOICE_IN_CREATE: 'invoiceIn/create',
  INVOICE_IN_EDIT: 'invoiceIn/edit',
  INVOICE_IN_DELETE: 'invoiceIn/delete',
  INVOICE_IN_GET_DETAILS: 'invoiceIn/detail',
  INVOICE_IN_PUBLIC: 'invoiceIn',
  INVOICE_IN_SEND_VALIDATE_EMAIL: 'invoiceIn/sendValidateEmail',
  INVOICE_IN_VALIDATORS_LIST: 'invoiceIn/validatorsList',
  INVOICE_IN_MARK_AS_PAID: 'invoiceIn/markAsPaid',
  UPDATE_MARK_AS_PAID: 'invoiceIn/editPayment',
  DELETE_MARK_AS_PAID: 'invoiceIn/deletePayment',
  GET_MARK_AS_PAID: 'invoiceIn/getPayment',
  DASHBOARD_DATA: '/invoice/dashboardData',
  GET_INVOICE_IN_LIST: '/invoiceIn/list',
  INVOICE_IN_DASHBOARD_DATA: 'invoice/dashboardData',
  ADD_BANK_DETAILS: 'bankDetails/create',
  GET_BANK_DETAILS_LIST: 'bankDetails/list',
  DELETE_BANK_DETAILS: 'bankDetails/delete',
  GET_BANK_DETAILS: 'bankDetails/data',
  EDIT_BANK_DETAILS: 'bankDetails/update',
  INVOICE_IN_SEND_EMAIL: '/invoiceIn/sendEmail',
  INVOICE_IN_VALIDATE: 'invoiceIn/validate',
  TEMP_INVOICE_CREATE: 'tempInvoice/create',
  TEMP_INVOICE_UPDATE_USER: 'tempInvoice/updateUser',
  TEMP_GET_INVOICE_DATA: 'tempInvoice',
  INVOICE_MARK_PAYMENT: 'invoice/markAsPaid',
  INVOICE_DELETE_MARK_PAYMENT: 'invoice/deletePayment',
  INVOICE_UPDATE_STATUS: 'invoice/editInvoiceStatus',
  INVOICE_PUBLIC_MARK_PAYMENT: 'invoice/markAsPaidPublic',
  INVOICE_APPROVE_PAYMENT: 'invoice/approvePayment',
  INVOICE_UPDATE_MARK_PAYMENT: 'invoice/editPayment',
  DASHBOARD_INVOICE_PERFORMANCE: 'invoice/performance',
  GET_COMPANY_REGISTRATION_DETAILS: 'company/company-back-office-details',
  BACK_OFFICE_SIGN_UP: 'backOfficeInvoice/signUp',
  VALIDATE_COMPANY_USER: 'backOfficeInvoice/validateCompanyUser',
  COMPANY_STACKHOLDERS_DETAILS: 'company/company-stake-holder-details',
  COMPANY_DOCUMENTS_DETAILS: 'company/company-document-details',
  COMPANY_LATEST_UPDATES: 'company/company-latest-document-details',
  COMPANY_SECRETARY_DETAILS: 'company/company-secretary-document-details',
  PUBLIC_SENDGRID_SENDEMAIL: 'public/sendGrid/sendEmail',
  COMPANY_SECRETARY_SHARE: 'company/secretary-document-share-detail',
  COMPANY_MAILROOM_SHARE: 'company/document-share-detail',
  CHECK_SEND_EMAIL: '/invoice/checkerSendEmail',
  CHECK_VALIDATE_SEND_EMAIL: '/invoice/checkerValidateSendEmail',
  PASSPORT_UPLOAD: 'get-passport-data',
  GET_COMPANY_SET_UP_DETAILS: 'company/company-details-incorporation',
  GET_COMPANY_SET_UP_UPDATE: 'company/update-company-by-incorporation',
  COMPANY_SIGNUP: 'invoice/signupIncorporationViaBackOffice',
  COMPANY_VERIFY_OTP: 'invoice/verifyIncorporationUserOtpViaBackOffice',
  EXISTING_USER_LOGIN: 'company/existing-invoice-user-signup-incorporation',
  GET_COMPANIES_LIST: 'company/get-all-companies-of-user-by-email',
  SIGNUP_INCORPORATION: 'company/signup-incorporation',
  CREATE_USER_AND_UPDATE_INCORPORATION: 'company/create-user-and-update-incorporation',
  SWITCH_COMPANY: 'company/switch-company',
  UPLOAD_DOCUMENT: '/upload-document',
  PREVIEW_DOCUMENT: '/company/preview-company-document',
  COMPANY_VERIFY_INCORPORATION_OTP: 'company/verify-incorporation-user-otp',
  DELETE_STAKEHOLDER: 'company/delete-stake-holder',
  UPDATE_STAKEHOLDER: 'company/update-stakeholder',
  SIGNUP_OTPS: `auth-management/${V1}/signup-otps`,
  V1_USERS: `auth-management/${V2}/users`,
  VALIDATE: `${V1}/passwordToken/validate`,
  V2_PASSWORD: `${V2}/password`,
  V1_CREDENTIALS: `${V1}/credentials`,
  V2_CREDENTIALS: `auth-management/${V2}/credentials`,
  V1_TOKEN: `${V1}/token`,
  V1_PROSPECTS: `onboarding-management/${V1}/prospects`,
  COMPANY_INFO: 'company-info',
  INDUSTRIES: `reference-data-management/${V1}/onboarding-forms/industries`,
  COUNTRIES: `public/${V1}/countries?filterRestrictedCountriesForOnboarding=false`,
  NATIONALITIES: `public/${V1}/nationalities?filterRestrictedNationalitiesForOnboarding=false`,
  BUSINESS_INFO: 'business-info',
  USERS: `auth-management/${V2}/users`,
  STAKEHOLDER_INDIVIDUALS: 'stakeholder-individuals',
  STAKEHOLDER_COMPANIES: 'stakeholder-companies',
  THIRD_PARTIES: 'third-parties',
  INDIVIDUAL_STAKEHOLDER_DOCUMENTS: 'stakeholder-individual-documents',
  COMPANY_STAKEHOLDER_DOCUMENTS: 'stakeholder-company-documents',
  APPLICATION_SUBMISSION: '/submit',
  OTHER_JURISDICTION_COUNTRIES: `public/${V1}/other-countries-of-incorporation`,
  PAYMENT_COUNTRIES: `bank-account-management/${V1}/countries?payments-filter=true`,
  COUNTRY_DIAL_CODES: `public/${V1}/countries-dial-codes`,
  PHONE_VALIDATION_OTPS: `auth-management/${V1}/phone-validation-otps`,
  UPDATE_PHONE: `auth-management/${V1}/update-phone`,
  GENERATE_MFA_OTP: `auth-management/v1/mfa-process`,
  VERIFY_MFA_OTP: `auth-management/v1/login`
};

export const SUCCESSFULL_SIGNUP = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/6247a595-30cc-4bdf-8451-e12f5b56e24d'
};

export const HUBSPOT_INTEGRATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/b88e67a2-d3e6-4c16-a6a0-d182653e8c5c'
};
export const PARTNER_HUBSPOT_INTEGRATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/8d9e1848-3fbf-4b98-b98d-85d7e39f099b'
};
export const RESOURCES_HUBSPOT_INTEGRATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/00874903-1a34-436f-b9ce-ad5def68956e'
};
export const REFER_FRIEND_HUBSPOT_INTEGRATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/2b1e3b83-f48f-4d25-bf0f-b2cffbaf5130'
};
export const GEO_LOCATION_API = 'https://geolocation-db.com/json/';

export const COMPANY_REGISTRATION_WAITING_FLOW = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/6f6ad58b-b78e-4024-8a8b-672d1f717e17'
};

export const COMPANY_INCORPORATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/4e3354c6-126f-4948-9393-a206a0b2d954'
};

export const CHECK_REFERRAL_CODE_HUBSPOT_INTEGRATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/91f394aa-5c05-4f30-b80a-f0745bedf2d6'
};
export const BUSINESS_ACCOUNT_REVIEWS_QUIZ = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/47a5c076-39b2-4ca1-8dff-6d3324b3054f'
};
export const PAYPAL_CALCULATOR = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/fde53732-306f-47ab-a6c1-8fc50f70878a'
};
export const NEW_COMPANY_INCORPORATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/616c1ccd-5189-4381-82c6-fdc2f6fc6bf6'
};
