import React, { useState } from 'react';
import H4HeaderText from '../../ui/typography/H4HeaderText';
import DynamicModal from '../../ui/modal/DynamicModal';
import MSText from '../../ui/typography/MSText';
import XSText from '../../ui/typography/XSText';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import Iconify from '../../Iconify';
import {
  checkValueExistOrNot,
  convertDateToDDMMYYYY,
  downloadImage,
  getFileExtension,
  makeFirstLetterCapitalize,
  stringTruncate
} from '../../../helper/helpers';
import { FILE_EXTENTION_TYPE, STRING_MAX_CHAR } from '../../../constants/enum';
import EmptyStageImg from '../../../images/icons/company/empty-stage-gray-cloud.svg';
import StatrysLoader from '../../ui/loaders/StatrysLoader';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

function ViewDetailsModal({ open, closeModal, shareholderData, title, previewDocumentUrl, previewDocumentUrlLoading }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  function handlePageShifting(index) {
    setPageNumber(index + 1);
  }
  return (
    <div>
      <DynamicModal openDynamicModal={open} closeDynamicModal={closeModal} maxWidth="920px">
        <H4HeaderText title={title} />
        <div className="flex mt-6">
          <div>
            {previewDocumentUrlLoading ? (
              <StatrysLoader />
            ) : (
              <>
                {getFileExtension(previewDocumentUrl) === FILE_EXTENTION_TYPE.PNG ||
                getFileExtension(previewDocumentUrl) === FILE_EXTENTION_TYPE.JPEG ||
                getFileExtension(previewDocumentUrl) === FILE_EXTENTION_TYPE.JPG ? (
                  <img
                    src={previewDocumentUrl ? previewDocumentUrl : EmptyStageImg}
                    alt="passport image"
                    className="h-60 object-contain"
                    width="364"
                    height="240"
                  />
                ) : (
                  <div>
                    <Document file={previewDocumentUrl} onLoadSuccess={onDocumentLoadSuccess}>
                      <div className="w-72">
                        <Page pageNumber={pageNumber} key={pageNumber} />
                      </div>
                      {pageNumber > 0 && (
                        <div className="importing-invoice-multi-pages flex mt-6 ">
                          {Array.from(new Array(numPages), (el, index) => (
                            <>
                              {numPages > 1 && (
                                <div className={`${index + 1 === pageNumber && 'active-pdf-tab'} mr-4`}>
                                  <Page
                                    className={`other-pdf-container cursor-pointer`}
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    width={70}
                                    height={100}
                                    onClick={() => handlePageShifting(index)}
                                  />
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                      )}
                    </Document>
                  </div>
                )}
              </>
            )}

            {previewDocumentUrl && (
              <div className="flex justify-end mt-2">
                <PrimaryButton
                  id="view-details-pdf-download"
                  caption={
                    <div className={`flex justify-center gap-2 items-center`}>
                      <Iconify icon={'lucide:download-cloud'} />
                      <span>Download</span>
                    </div>
                  }
                  onClick={() => downloadImage(previewDocumentUrl, `${shareholderData?.firstName}-passport`)}
                  type="small"
                  bgColor="bg-white"
                  className="company-white-button mb-2"
                />
              </div>
            )}
          </div>

          <div className=" ml-8">
            <div className="flex">
              <div className="w-52">
                <MSText textColor="text-gray-450 mb-2" title="Lastname" />
                <XSText
                  className="mb-4"
                  title={checkValueExistOrNot(makeFirstLetterCapitalize(shareholderData?.lastName))}
                />
                <MSText textColor="text-gray-450 mb-2" title="Passport" />
                <XSText className="mb-4" title={checkValueExistOrNot(shareholderData?.passportId)} />
                <MSText textColor="text-gray-450 mb-2" title="Nationality" />
                <XSText className="mb-4" title={checkValueExistOrNot(shareholderData?.nationality?.name)} />
              </div>
              <div className="ml-4 w-52">
                <MSText textColor="text-gray-450 mb-2" title="Name" />
                <XSText
                  className="mb-4"
                  title={
                    shareholderData?.firstName
                      ? makeFirstLetterCapitalize(shareholderData?.firstName) +
                        ' ' +
                        makeFirstLetterCapitalize(shareholderData?.lastName)
                      : '-'
                  }
                />
                <MSText textColor="text-gray-450 mb-2" title="Expiry" />
                <XSText className="mb-4" title={checkValueExistOrNot(convertDateToDDMMYYYY(shareholderData?.expiry))} />
              </div>
            </div>
            <MSText textColor="text-gray-450 mb-2" title="Residential address" />
            <XSText
              className="mb-4 whitespace-pre-line"
              title={checkValueExistOrNot(shareholderData?.correspondenceAddress)}
            />
            <div className="flex">
              <div className="w-52">
                <MSText textColor="text-gray-450 mb-2" title="Email" />
                <XSText
                  title={checkValueExistOrNot(stringTruncate(shareholderData?.email, STRING_MAX_CHAR.TWENTY_FIVE))}
                />
              </div>
              <div className="ml-4 w-52">
                <MSText textColor="text-gray-450 mb-2" title="Phone number" />
                <XSText title={checkValueExistOrNot(shareholderData?.phoneNumber)} />
              </div>
            </div>
          </div>
        </div>
      </DynamicModal>
    </div>
  );
}

export default ViewDetailsModal;
