import { Matching401ApiErrorStatus } from '../../helper/helpers';
import axios from 'axios';
import { navigate } from 'gatsby';
import Emitter from '../../service/emitter';
import { EMITTER_CONSTANTS, API_ERROR_MESSAGES, API_ERROR_CODES, STATUS } from '../../constants/enum';
import { PATH_PAGE } from '../../routes/paths';
import { ENDPOINTS } from '../../service/services';
const BASE_API_URL = process.env.BASE_API_URL;
const instance = axios.create({
  baseURL: BASE_API_URL
});
instance.interceptors.request.use(
  async (config) => {
    const token = await sessionStorage.getItem('token');
    if (token && token?.length > 0) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return err;
  }
);

const responseHandler = (response) => {
  return response;
};
instance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => {
    if (process.env.MAINTENANCE_FLOW_ENABLED === STATUS.TRUE) {
      if (typeof window !== `undefined`) {
        navigate('/under-maintenance');
      }
    }
    if (Matching401ApiErrorStatus(error)) {
      sessionStorage.removeItem('token');
      navigate(PATH_PAGE?.login);
    } else {
      if (
        error?.response?.data?.statusCode !== API_ERROR_CODES?.STATUS_405 &&
        error?.config?.url !== ENDPOINTS.UPLOAD_DOCUMENT
      ) {
        Emitter.emit(EMITTER_CONSTANTS.INPUT_FROM_MAIN, {
          snackbarState: true,
          errorCode: `${error?.response?.data?.statusCode} - ${
            error?.response?.data?.error ? error?.response?.data?.error : API_ERROR_MESSAGES.DEFAULT
          }`,
          errorMessage: error?.response?.data?.message
        });
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
