import React, { memo } from 'react';
import Avatar from '../../ui/Avatar';
import MSText from '../../ui/typography/MSText';
import XSText from '../../ui/typography/XSText';

const EditOwnerDetails = memo(({ data = {} }) => {
  const { firstName, lastName, isShareholder, isDirector, isMainUser } = data;
  const avatarText = firstName && firstName?.charAt(0) + lastName?.charAt(0);
  return (
    <div className="flex justify-between py-4">
      <div className="flex gap-4">
        <Avatar avatarName={avatarText} />
        <div>
          <XSText fontWeight="text-bold mb-2 capitalize" title={firstName && lastName && firstName + ' ' + lastName} />
          <div className="flex gap-2 items-center">
            <MSText className="card-type-text flex items-center" title={isMainUser ? 'Yourself' : 'Individual'} />
            {isShareholder ? (
              <MSText
                className="role-owner flex items-center justify-center px-2 py-1"
                fontWeight="text-bold"
                title="Owner"
              />
            ) : null}
            {isDirector ? (
              <MSText
                className="role-director flex items-center justify-center px-2 py-1"
                fontWeight="text-bold"
                title="Director"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
});
export default EditOwnerDetails;
