import React, { useEffect, useState } from 'react';
import { Box, Card, Divider, Table, TableBody, TableContainer, TablePagination, TextField } from '@mui/material';
import useTable, { emptyRows } from '../../../hooks/useTable';
import { TableNoData, TableEmptyRows } from '../../table';
import PropTypes from 'prop-types';
import StatrysLoader from '../../ui/loaders/StatrysLoader';
import BaseText from '../../ui/typography/BaseText';
import Scrollbar from '../../Scrollbar';
import { STATUS_COLORS } from '../../../constants/enum';
import MailRoomViewRow from '../../../sections/@company/MailRoomViewRow';
import MailRoomDetails from './MailRoomDetails';
import { downloadImage } from '../../../helper/helpers';

function MailRoomViewTable({ selectedRow, documentsDetails, viewRowData, documentURLQuery, companyId }) {
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: 'dateCreated'
  });
  const [tableData, setTableData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState();
  const [isViewableTable, setIsViewableTable] = useState(false);
  const [viewAbleData, setViewAbleData] = useState();

  const isNotFound = tableData?.length === 0;

  const handleShareModal = () => {};
  const handleViewDetails = () => {};
  const handleSelectedRow = (id, rowData) => {
    setViewAbleData(rowData);
    setIsViewableTable(true);
    setSelectedRowData(id);
  };

  useEffect(() => {
    setTableData(documentsDetails);
    setViewAbleData(viewRowData);
    setSelectedRowData(selectedRow);
  }, [documentsDetails, selectedRow, viewRowData]);

  return (
    <>
      <div className="flex mt-10">
        <div className="company-view-container mr-4">
          <Card
            sx={{
              '&.MuiPaper-root': {
                position: 'unset',
                boxShadow: 'none'
              }
            }}
          >
            <div className="m-6">
              <BaseText title="Mailroom" fontSize="text-lg" textColor="text-blue-100" fontWeight="text-bold" />
            </div>
            <Divider />
            <Scrollbar>
              <TableContainer sx={{ position: 'relative' }}>
                <Table>
                  <TableBody>
                    {tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <>
                        <MailRoomViewRow
                          handleShareAction={handleShareModal}
                          handleViewAction={handleViewDetails}
                          handleSelectedRow={() => handleSelectedRow(row?._id, row)}
                          isViewableTable={isViewableTable}
                          selectedRowData={selectedRowData}
                          row={row}
                          selectedRow={selectedRow}
                        />
                      </>
                    ))}
                    <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, tableData?.length)} />
                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                count={tableData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            </Box>
          </Card>
        </div>
        {viewAbleData ? (
          <MailRoomDetails
            viewdata={viewAbleData}
            documentURLQuery={`mailroomDocId=${selectedRowData}`}
            companyId={companyId}
          />
        ) : (
          <StatrysLoader />
        )}
      </div>
    </>
  );
}

export default MailRoomViewTable;

MailRoomViewTable.propTypes = {
  documentsDetails: PropTypes.func,
  viewRowData: PropTypes.object,
  selectedRow: PropTypes.any
};
